import React, { useLayoutEffect, useRef } from 'react'
import useEventListener from '../hooks/useEventListener'
import { useLocalState } from './Layout'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'

const Footer = () => {
    const internalLinkProps = {
      activeClassName: `active`,
      className: `font-sans text-xs tracking-wide`,
      entry: pageTransitionIn,
      exit: pageTransitionOut,
    },
    externalLinkProps = {
      className: `font-sans text-xs tracking-wide`,
      rel: `noopener noreferrer`,
      target: `_blank`,
    },
    footerRef = useRef(null),
    { footerHeight, setFooterHeight } = useLocalState(),
    checkFooterHeight = () => {
      if(!footerRef.current) return
      if(footerHeight !== footerRef.current.offsetHeight) setFooterHeight(footerRef.current.offsetHeight)
    }

  useLayoutEffect(() => {
    checkFooterHeight()
  }, [])

  useEventListener(
    `resize`,
    checkFooterHeight
  )

	return (
    <footer ref={footerRef} className="relative mt-48 p-5 pt-0 flex flex-wrap bg-green text-white">
      <style>{`
        :root {
          --footer-height: ${footerHeight}px;
        }
      `}</style>
      <div className="w-max pt-5 pr-10 flex flex-col">
        <p className={`mb-0.5 font-bold ${internalLinkProps.className}`}>Archive</p>
        <TransitionLink
          to="/"
          {...internalLinkProps}
        >
          All
        </TransitionLink>
        <TransitionLink
          to="/essays"
          {...internalLinkProps}
        >
          Essays
        </TransitionLink>
        <TransitionLink
          to="/interviews"
          {...internalLinkProps}
        >
          Interviews
        </TransitionLink>
        <TransitionLink
          to="/photo-stories"
          {...internalLinkProps}
        >
          Photo Stories
        </TransitionLink>
        <TransitionLink
          to="/poems"
          {...internalLinkProps}
        >
          Poems
        </TransitionLink>
      </div>
      <div className="w-max pt-5 pr-10 inline-flex flex-col">
        <p className={`mb-0.5 font-bold ${internalLinkProps.className}`}>Information</p>
        <TransitionLink
          to="/about"
          {...internalLinkProps}
        >
          About
        </TransitionLink>
        <TransitionLink
          to="/print"
          {...internalLinkProps}
        >
          Print
        </TransitionLink>
        <TransitionLink
          to="/podcast"
          {...internalLinkProps}
        >
          Podcast
        </TransitionLink>
        <TransitionLink
          to="/submissions"
          {...internalLinkProps}
        >
          Submissions
        </TransitionLink>
      </div>
      <div className="inline-flex justify-between flex-wrap flex-grow">
        <div className="ml-auto w-max pt-5 flex flex-col font-sans text-xs">
          <TransitionLink
            className="mb-0.5 font-bold"
            entry={internalLinkProps.entry}
            exit={internalLinkProps.exit}
            to="/"
            >
            © {new Date().getFullYear()} Clerestory Magazine
          </TransitionLink>
          <a href="https://studio.sl">Website Spevack Loeb</a>
        </div>
      </div>
    </footer>
	)
}

export default Footer
export { Footer }