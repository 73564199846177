import { useMedia } from "./useMedia"

const useTransitionClasses = ({ transitionStatus, entry, exit }) => {
  const prefersReducedMotion = useMedia(
    [`(prefers-reduced-motion: reduce)`],
    [true],
    false
  )
  let transitionClasses = ``

  if (prefersReducedMotion) {
    // None of that
  } else {
    // Buncha motion classes
    switch (transitionStatus) {
      case `entering`:
        transitionClasses = `transition-opacity opacity-100 duration-300`
        break
      case `entered`:
        transitionClasses = `transition-opacity opacity-100 duration-300`
        break
      case `exiting`:
        transitionClasses = `transition-opacity opacity-0 duration-300`
        break
        case `exited`:
          transitionClasses = `transition-opacity opacity-0 duration-300`
          // Scroll to top immediately to synchronize paints (don’t wait for Gatsby to scroll)
          document.documentElement.scrollTop = 0
        break
      default:
        break
    }
  }

  return transitionClasses
}

export default useTransitionClasses
export { useTransitionClasses }