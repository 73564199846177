/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

function Seo({ description, image, lang, meta, title }) {
	const {
		prismicMetadata: {
			data: { og_description, og_image, og_title, twitter_name },
		},
	} = useStaticQuery(
		graphql`
			query MyQuery {
				prismicMetadata {
					data {
						og_description {
							text
						}
						og_image {
              gatsbyImageData
						}
						og_title {
							text
						}
						twitter_name {
							text
						}
					}
				}
			}
		`
	)

	const location = useLocation(),
    metaDescription = description && description !== ``
      ? description
      : og_description.text,
    appendSiteName = ` | Clerestory Magazine`,
    seoTitle = title ?? og_title.text,
    seoImage = image ?? og_image

  let seoImageQueries, seoImageSrc, seoImageWidth, seoImageHeight, seoImageRatio
  // let facebookImageQueries, facebookImageSrc, twitterImageQueries, twitterImageSrc

  // Resize image to max 1200w
  if(seoImage?.gatsbyImageData?.images?.fallback?.src) {
    seoImageRatio = seoImage?.gatsbyImageData?.height / seoImage?.gatsbyImageData?.width
    seoImageWidth = Math.min(seoImage?.gatsbyImageData?.width, 1200)
    seoImageHeight = Math.round(seoImageRatio * seoImageWidth)
    seoImageQueries = queryString.parse(seoImage.gatsbyImageData.images.fallback.src.split(`?`)[1])
    if(seoImageQueries?.w) seoImageQueries.w = seoImageWidth
    if(seoImageQueries?.h) seoImageQueries.h = seoImageHeight
    seoImageSrc = `${seoImage.gatsbyImageData.images.fallback.src.split(`?`)[0]}?${queryString.stringify(seoImageQueries)}`

    // Override all above with 1200 x 630 for Facebook
    // facebookImageQueries = queryString.parse(seoImage.gatsbyImageData.images.fallback.src.split(`?`)[1])
    // facebookImageQueries.w = 1200
    // facebookImageQueries.h = 630
    // facebookImageQueries.fit = `fill`
    // facebookImageQueries.fill = `blur`
    // facebookImageSrc = `${seoImage.gatsbyImageData.images.fallback.src.split(`?`)[0]}?${queryString.stringify(facebookImageQueries)}`
    
    // And 1200 x 600 for Twitter
    // twitterImageQueries = queryString.parse(seoImage.gatsbyImageData.images.fallback.src.split(`?`)[1])
    // twitterImageQueries.w = 1200
    // twitterImageQueries.h = 600
    // twitterImageQueries.fit = `fill`
    // twitterImageQueries.fill = `blur`
    // twitterImageSrc = `${seoImage.gatsbyImageData.images.fallback.src.split(`?`)[0]}?${queryString.stringify(twitterImageQueries)}`
  }

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={seoTitle}
			titleTemplate={`%s${appendSiteName}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:site_name`,
					content: `Clerestory Magazine`,
				},
				{
					property: `og:title`,
					content: seoTitle.indexOf(appendSiteName) < 0
            ? `${seoTitle}${appendSiteName}`
            : seoTitle
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:image:width`,
					content: seoImageWidth,
          // content: facebookImageQueries?.w,
				},
				{
					property: `og:image:height`,
					content: seoImageHeight,
          // content: facebookImageQueries?.h,
				},
				{
					property: `og:image`,
					content: seoImageSrc,
          // content: facebookImageSrc,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:url`,
					content: `https://clerestorymag.com${location.pathname}`,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:title`,
					content: seoTitle.indexOf(appendSiteName) < 0
            ? `${seoTitle}${appendSiteName}`
            : seoTitle
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `twitter:image`,
					content: seoImageSrc,
          // content: twitterImageSrc,
				},
				{
					name: `twitter:site`,
					content: twitter_name.text,
				},
			].concat(meta)}
      link={[
        {
          rel: `canonical`,
          href: `https://clerestorymag.com${location.pathname}`,
        },
      ]}
		/>
	)
}

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
}

export default Seo
